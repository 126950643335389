@import url(~antd/dist/antd.min.css);
.__ReactTableContainer {
  display: grid;
  width: 100%; }
  .__ReactTableContainer .__ReactTableWrap {
    display: block;
    max-width: 100%;
    overflow: auto;
    margin-bottom: 30px;
    max-height: 100vh; }
    .__ReactTableContainer .__ReactTableWrap .__ReactTable {
      width: 100%;
      background: #ffffff;
      color: #212529;
      border-collapse: separate;
      border-spacing: 0; }
      .__ReactTableContainer .__ReactTableWrap .__ReactTable .__theadReactTable tr {
        padding: 0.75rem; }
        .__ReactTableContainer .__ReactTableWrap .__ReactTable .__theadReactTable tr th {
          vertical-align: top;
          padding: 0.6rem;
          display: table-cell;
          z-index: 99;
          border-bottom: 2px solid #c0d1dc; }
      .__ReactTableContainer .__ReactTableWrap .__ReactTable .__globalFilter {
        position: sticky;
        top: 0;
        z-index: 99; }
      .__ReactTableContainer .__ReactTableWrap .__ReactTable .__tbodyReactTable {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit; }
        .__ReactTableContainer .__ReactTableWrap .__ReactTable .__tbodyReactTable tr {
          display: table-row;
          vertical-align: inherit; }
          .__ReactTableContainer .__ReactTableWrap .__ReactTable .__tbodyReactTable tr th,
          .__ReactTableContainer .__ReactTableWrap .__ReactTable .__tbodyReactTable tr td {
            padding: 0.6rem;
            vertical-align: top; }
  .__ReactTableContainer .__backgroundGreyReactTable {
    background: #dee2e6; }
  .__ReactTableContainer .__backgroundWhiteReactTable {
    background: white; }
  .__ReactTableContainer .__inputGlobalFilterReactTable::placeholder {
    color: #868687;
    opacity: 1; }
  .__ReactTableContainer .__inputGlobalFilterReactTable:-ms-input-placeholder {
    color: #868687; }
  .__ReactTableContainer .__inputGlobalFilterReactTable::-ms-input-placeholder {
    color: #868687; }
  .__ReactTableContainer .__inputGlobalFilterReactTable {
    font-size: "1.1rem";
    border: none; }
  .__ReactTableContainer .__inputGlobalFilterReactTable input {
    box-shadow: none; }
  .__ReactTableContainer th[role="columnheader"] {
    background: #dee2e6;
    border-bottom: 2px solid #c0d1dc; }
  .__ReactTableContainer th[data-sticky-first-right-td] {
    background: #dee2e6;
    border-bottom: 2px solid #c0d1dc;
    border-left: 2px solid #c0d1dc; }
  .__ReactTableContainer th[data-sticky-last-left-td] {
    background: #dee2e6;
    border-bottom: 2px solid #c0d1dc;
    border-right: 2px solid #c0d1dc;
    z-index: 100 !important; }
  .__ReactTableContainer [data-sticky-last-left-td] {
    border-right: 2px solid #c0d1dc;
    left: 0; }
  .__ReactTableContainer [data-sticky-first-right-td] {
    border-left: 2px solid #c0d1dc;
    right: 0; }

.__paginationReactTable {
  z-index: 1;
  margin-bottom: 30px;
  position: relative;
  width: 100%; }
  .__paginationReactTable .__inputPagePaginationReactTable {
    margin-bottom: 3px; }
  .__paginationReactTable .__navPageReactTable {
    text-align: right; }
    .__paginationReactTable .__navPageReactTable button {
      margin-left: 2px;
      margin-right: 2px;
      margin-bottom: 2px; }

#__btnLastPageReactTable {
  margin-right: 0 !important; }

@media (max-width: 576px) {
  .__navPageReactTable {
    position: relative;
    float: right;
    margin-top: 20px; } }

@media (min-width: 577px) and (max-width: 767px) {
  .__navPageReactTable {
    position: relative;
    float: right;
    margin-top: 20px; } }

.__buttonCloseReactModal {
  position: absolute;
  right: 5px;
  font-family: PoppinsSemibold, sans-serif;
  background: no-repeat;
  border: none;
  color: #212121;
  font-size: 20px;
  top: 8px; }
  .__buttonCloseReactModal:hover span {
    transition: all 0.2s ease-in-out;
    transform: scale(1.01) translateY(-2px); }
  .__buttonCloseReactModal span {
    transform: translateY(-2px);
    transition: all 0.1s ease-in-out; }

.__buttonCloseReactModal:hover,
.__buttonCloseReactModal:active,
.__buttonCloseReactModal:focus {
  position: absolute;
  right: 5px;
  font-family: PoppinsSemibold, sans-serif;
  background: no-repeat;
  border: none;
  color: grey;
  font-size: 20px;
  top: 8px; }

@keyframes __modalFadeReactModal {
  from {
    transform: translateY(-50%);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

.__containerReactModal section {
  max-height: 85vh;
  overflow-y: hidden;
  background: transparent; }

.__headerReactModal {
  color: white !important;
  height: 50px;
  line-height: 48px;
  text-align: center;
  border-bottom: 1px solid #ecf0f3; }

.__headerReactModal .headerTitle {
  font-weight: bold;
  color: #212121 !important; }

.__containerReactModal {
  animation-name: __modalFadeReactModal;
  animation-duration: 0.3s;
  top: 35px;
  position: relative; }

.__contentReactModal {
  padding: 15px;
  background: #ffffff;
  max-height: 67vh;
  overflow-x: auto; }

.__contentReactModalBig {
  padding: 20px;
  padding-bottom: 0;
  background: #ffffff;
  max-height: 95vh;
  overflow-x: auto; }

.__footerReactModal {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px !important;
  text-align: center;
  background: #ffffff; }

.ReactModal__Overlay--after-open {
  z-index: 999; }

.Toastify__toast-container {
  z-index: 99999 !important; }
